link {
  font-weight: bold;
  text-decoration: underline;
  color: darkblue;
}

.subNavLink {
  color: white;
}

.subNavLinkActive {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.cell-danger {
  font-weight: bold;
  background-color: darkred;
  color: white;
  padding-top: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cell-warning {
  font-weight: bold;
  background-color: rgb(196, 176, 0);
  color: white;
  padding-top: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cell-success {
  font-weight: bold;
  background-color: green;
  color: white;
  padding-top: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cell-text-danger {
  font-weight: bold;
  color: darkred;
}

.cell-text-danger-dark {
  font-weight: bold;
  color: rgb(48, 1, 1);
}

.cell-text-success {
  color: green;
}

.note-overflow-container {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.note-card {
  border: thin solid black;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #feffe8;
}

.note-card-subject {
  font-weight: bold;
  font-size:16px;
  padding-bottom:3px;
  padding-top:3px;
}

.note-card-updated {
  font-weight: bold;
}

.note-card-users {
  font-weight: bold;
}

.note-card-body {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 8px;
}

.note-card-status {
  font-weight: bold;
}


.row-header {
  font-weight: bold;
  text-decoration: underline;
}

.row-header-sortable {
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  cursor: default;
}

.row-warning {
  background-color: #fcf8e3;
}

.row-danger {
  background-color: #f2dede;
}

.list-row {
  margin-bottom: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.list-row-odd {
  background-color: rgba(0,0,0,0.03);
}

.school-users-row {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.vertical-container {
  display: table;
}

.vertical-container > .vertical-center, .vertical-container > span, .vertical-container > div {
  vertical-align: bottom;
}

.rbc-day-slot .rbc-event-label{
  display: none;
}

.schedule-event-done {
  background-color: slategray
}

.schedule-event-pending {
  background-color: deepskyblue
}

.schedule-event-private {
  background-color: #8B0000
}

/* Floating Action Button */
.floating-menu {
	position: absolute;
	right: 8px;
	bottom: 8px;
	height: 42px;
	text-align: right;
	/*transition: height 250ms ease-in-out;*/
}

.floating-menu.open {
	height: 150px;
	overflow: hidden;
}

.floating-menu.open .floating-menu-item {
	margin-top: 8px;
}

.floating-menu-item label{
	display: inline-block;
	margin-right: 8px;
	padding: 4px 10px;
	border-radius: 12px;
	background: rgba(0,0,0,.25);
	opacity: 0;
	transition: opacity 250ms ease-in-out;
}

.floating-menu.open .floating-menu-item label {
	opacity: 0.4;
}

.floating-menu-icon{
	display: inline-block;
	width: 42px;
	height: 42px;
	line-height: 42px;
	vertical-align: middle;
	background: #e53935;
	border-radius: 50%;
	border: none;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
	text-align: center;
}

.floating-menu-icon .material-icons {
	font-size: 32px;
	color: #fff;
	vertical-align: middle;
}

/** DATE PICKER **/
.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/** MODAL **/
.modal-x-large {
  width: 94%;
}

/** SPINNER **/
.glyphicon.normal-right-spinner {
  -webkit-animation: glyphicon-spin-r 2s infinite linear;
  animation: glyphicon-spin-r 2s infinite linear;
}

@-webkit-keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

.help-container {
  display: block;
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  background-color: #fffde7;
  border-radius: 3px;
}

/** Markdown **/
.markdown-help-container{
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.nav-tabs {
  margin-bottom: 10px;
}